import React, { useState } from 'react';
import { useInvoiceRowData } from './InvoiceRowDataContext';
import { useInvoiceFileData } from './InvoiceFileDataContext';
import { getQuote } from "../apiService";
import {useNavigate} from "react-router-dom";


const ValidateInvoicePage = () => {

    const [loading, setLoading] = useState(false);

    // Define state to manage the editable data
    const { rowData, setRowData } = useInvoiceRowData();
    console.log('Row Data:', rowData);
    const { file } = useInvoiceFileData();
    const [bookingDetails, setBookingDetails] = useState({ hawb: '', error: '' });

    // Function to handle input changes
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setRowData({
            ...rowData,
            [name]: value,
        });
    };


    const dimensions_length = rowData.dimensions_length || [];
    const dimensions_width = rowData.dimensions_width || [];
    const dimensions_height = rowData.dimensions_height || [];
    const dimensions_weight = rowData.dimensions_weight || [];

    // Find the maximum array length to render correct rows
    const maxRows = Math.max(
        dimensions_length.length,
        dimensions_width.length,
        dimensions_height.length,
        dimensions_weight.length
    );

    const handleInputTableChange = (event, index, field) => {
        const { value } = event.target;
        const numericValue = isNaN(Number(value)) ? '' : Number(value);

        setRowData((prevRowData) => {
          // Initialize the array if it doesn't exist
          const updatedArray = [...(prevRowData[field] || [])];
          updatedArray[index] = numericValue; // Update the value at the correct index
      
          // Log updated array for debugging
          console.log('Updated array:', updatedArray);
      
          return {
            ...prevRowData,
            [field]: updatedArray, // Update the corresponding field in rowData
          };
        });
      };





    const handleClearFields = () => {
        // Clear all fields by resetting the rowData state
        setRowData({
            delivery_company: 'Delivery Company Name',
            delivery_address: 'Delivery Company Address',
            delivery_city: 'Delivery Company City',
            delivery_country: 'Delivery Company Country',
            delivery_postcode: 'Delivery Company Postcode',
            delivery_contact: 'Delivery Company Contact',
            delivery_phone: 'Delivery Company Phone',
            collection_company: 'Collection Company',
            collection_address: 'Collection Address',
            collection_city: 'Collection City',
            collection_country: 'Collection Country',
            collection_postcode: 'Collection Postcode',
            collection_contact: 'Collection Contact',
            collection_phone: 'Collection Phone',
            consignment_contents_type: 'Consignment Contents Type',
            consignment_contents: 'Consignment Contents',
            consignment_value: 0,
            consignment_currency: 'Consignment Currency',
            consignment_origin_country: 'Consignment Origin Country',
            dimensions_weights: [],
            dimensions_lengths: [],
            dimensions_widths: [],
            dimensions_heights: []
        });
      };

    
      const navigate = useNavigate();
      
    // Updated encodePdfToBase64 function
    const encodePdfToBase64 = (file) => {
        return new Promise((resolve, reject) => {
            if (!file) {
                // If no file is provided, resolve with null
                resolve(null);
                return;
            }

            const reader = new FileReader();
            reader.readAsArrayBuffer(file); // Reading file as ArrayBuffer

            reader.onloadend = () => {
                try {
                    const base64String = btoa(
                        new Uint8Array(reader.result)
                            .reduce((data, byte) => data + String.fromCharCode(byte), '')
                    );
                    resolve(base64String); // Resolve the promise with the base64 string
                } catch (error) {
                    reject(error); // Reject if there's an error in encoding
                }
            };

            reader.onerror = (error) => reject(error); // Reject if reading fails
        });
    };
  


      const handleGetQuote = async () => {
        const today = new Date();
        const dd = String(today.getDate()).padStart(2, '0');
        const mm = String(today.getMonth() + 1).padStart(2, '0'); 
        const yyyy = today.getFullYear();
        const todayDate = yyyy + '-' + mm + '-' + dd;
        console.log(todayDate);
        console.log('Creating request for the quote');
        

        const packages = [];
        
        const proformaLines = [
            {
              goodsDescription: rowData.consignment_contents || null,
              itemValue: {
                amount: rowData.consignment_value || null,
                currency: rowData.consignment_currency || null,
              },
              noOfUnits: 1, 
            },
          ];
        

        for (let i = 0; i < maxRows; i++) {
        const packageItem = {
            height: rowData.dimensions_height[i] || null,
            length: rowData.dimensions_length[i] || null,
            noOfPiece: 1, 
            weight: rowData.dimensions_weight[i] || null,
            width: rowData.dimensions_width[i] || null,
        };
        
        // Add proformaLines only for index 0      
        if (i === 0) {
            packageItem.proformaLines = proformaLines;
        }
        
        // Push the generated package to the packages array
        packages.push(packageItem);
        }
        
        const documentImages = [
            {
                content: await encodePdfToBase64(file),
                description: 'Commercial Invoice',
                documentType: 'COMMERCIAL_INVOICE',
                fileType: 'PDF',
            },

        ];

        const requestBody = {
          bookThirdParty: false,
          bookingDate: todayDate,
          clientCode: 'MATAN DIGITAL EFI',//'kubalevi'
          clientReference: {
            reference1: null,
            reference2: 'dynamoAI', //null
          },
          collectionAddress: {
            addressLine1: rowData.collection_street || null,
            addressLine2: null,
            addressLine3: rowData.collection_city || null,
            addressLine4: rowData.collection_state || null,
            branch: 'Office',
            code: null,
            company: rowData.collection_company || null,
            contact: 'Heli', //rowData.collection_contact || null
            countryCode: rowData.collection_country || null,
            email: null, //'kubalevi1@gmail.com'
            fax: null,
            mobile: null,
            phone: rowData.collection_phone || null,
            placeName: rowData.collection_city || null,
            postcode: rowData.collection_postcode || null,
          },
          consignmentValue: {
            amount: rowData.consignment_value || null,
            currency: rowData.consignment_currency || null,
          },
          contents: rowData.consignment_contents || null,
          contentsType: rowData.consignment_contents_type || 'NON Documents',
          deliveryAddress: {
            addressLine1: rowData.delivery_street || null,
            addressLine2: null,
            addressLine3: rowData.delivery_city,
            addressLine4: rowData.delivery_state || null,
            company: rowData.delivery_company || null,
            contact: rowData.delivery_contact || null,
            countryCode: rowData.delivery_country || null,
            email: null, //'kubalevi1@gmail.com'
            fax: null,
            mobile: null,
            phone: rowData.delivery_phone,
            placeName: rowData.delivery_city || null,
            postcode: rowData.delivery_postcode || null,
          },
          documentImages: documentImages,
          notifyPoD: true,
          packages: packages,
          podEmailTo: 'heli.brandes@efi.com', //'kubalevi1@gmail.com'
          proforma: {
            countryofOrigin: rowData.consignment_origin_country || null,
          },
          senderDetails: {
            bookedBy: 'Heli1', //kobilevi
          },
          shipmentType: {
            productName: 'Export', //need to set as per a combo box on the screen - Export (Default), Import, Drop, Domestic 
          },
          //need to add fields - shippersLoadAndCount , specialInstruction , termsOfSale , bookingConfirmationNumber 
        };
        console.log('Request for Get Quote - ', JSON.stringify(requestBody, null, 2));
        let trackingNumTemp = '';
        try {
            setLoading(true);
            const data = await getQuote(requestBody);
            console.log("Get Quote Response");
            console.log(data);

            if (data.status) {
                setBookingDetails({ hawb: data.bookingDetails.hawb, error: '' });
                trackingNumTemp = data.bookingDetails.hawb;
              } else {
                console.error('Errors:', data.errors);
                const errorMessage = data.errors.map(error => `code: ${error.code}, description: ${error.description}`).join(' | ');
                setBookingDetails({ hawb: '', error: 'Errors: ' + errorMessage });
                trackingNumTemp = errorMessage;
              }

          } catch (error) {
            alert(error.message)
            console.error('Error getting quote:', error);
            const errorMessage = `Error: ${error.message}`;
            setBookingDetails({ hawb: '', error: errorMessage });
            trackingNumTemp = errorMessage;
          }
          finally {
            setLoading(false);
            navigate(`/quotes?trackingNum=${encodeURIComponent(trackingNumTemp)}`);
          }
      };
      

            

    return (
        <>
            <h1>Validate Invoice Page</h1>
            <div>
                <label>
                    Note the Fields we retrieved out of your invoice Which You can edit the below, and once satisfied, click Get Quote
                </label>     
                {file ? (
                    <p>Selected File: {file.name}</p>
                ) : (
                    <p>No file selected</p>
                )}
                <div>
                    <button onClick={handleClearFields}>Clear All Fields</button>
                    
                </div>

            </div>

            <div className="form-container">
              <div className="category-row"> 

                {/* Collection Category */}
                <div className="category-column">
                    <h2>Collection</h2>

                    <div className="form-field">
                    <label>Company Name</label>
                    <input
                        type="text"
                        name="collection_company"
                        value={rowData.collection_company || ''}
                        onChange={handleInputChange}
                    />
                    </div>

                    <div className="form-field">
                    <label>Street</label>
                    <input
                        type="text"
                        name="collection_street"
                        value={rowData.collection_street || ''}
                        onChange={handleInputChange}
                    />
                    </div>

                    <div className="form-field">
                    <label>City</label>
                    <input
                        type="text"
                        name="collection_city"
                        value={rowData.collection_city || ''}
                        onChange={handleInputChange}
                    />
                    </div>


                    <div className="form-field">
                    <label>State</label>
                    <input
                        type="text"
                        name="collection_state"
                        value={rowData.collection_state || ''}
                        onChange={handleInputChange}
                    />
                    </div>

                    <div className="form-field">
                    <label>Postcode</label>
                    <input
                        type="text"
                        name="collection_postcode"
                        value={rowData.collection_postcode || ''}
                        onChange={handleInputChange}
                    />
                    </div>

                    <div className="form-field">
                    <label>Country</label>
                    <input
                        type="text"
                        name="collection_country"
                        value={rowData.collection_country || ''}
                        onChange={handleInputChange}
                    />
                    </div>


                    <div className="form-field">
                    <label>Phone</label>
                    <input
                        type="text"
                        name="collection_phone"
                        value={rowData.collection_phone || ''}
                        onChange={handleInputChange}
                    />
                    </div>
                    

                    <div className="form-field">
                    <label>Contact Name</label>
                    <input
                        type="text"
                        name="collection_contact"
                        value={rowData.collection_contact || ''}
                        onChange={handleInputChange}
                    />
                    </div>
                    

                    {/* Add more fields as needed */}
                </div>

                {/* Delivery Category */}
                <div className="category-column">
                    <h2>Delivery</h2>

                    <div className="form-field">
                    <label>Company Name</label>
                    <input
                        type="text"
                        name="delivery_company"
                        value={rowData.delivery_company || ''}
                        onChange={handleInputChange}
                    />
                    </div>

                    <div className="form-field">
                    <label>Street</label>
                    <input
                        type="text"
                        name="delivery_street"
                        value={rowData.delivery_street || ''}
                        onChange={handleInputChange}
                    />
                    </div>
                    
                    <div className="form-field">
                    <label>City</label>
                    <input
                        type="text"
                        name="delivery_city"
                        value={rowData.delivery_city || ''}
                        onChange={handleInputChange}
                    />
                    </div>


                    <div className="form-field">
                    <label>State</label>
                    <input
                        type="text"
                        name="delivery_state"
                        value={rowData.delivery_state || ''}
                        onChange={handleInputChange}
                    />
                    </div>


                    <div className="form-field">
                    <label>Postcode</label>
                    <input
                        type="text"
                        name="delivery_postcode"
                        value={rowData.delivery_postcode || ''}
                        onChange={handleInputChange}
                    />
                    </div>


                    <div className="form-field">
                    <label>Country</label>
                    <input
                        type="text"
                        name="delivery_country"
                        value={rowData.delivery_country || ''}
                        onChange={handleInputChange}
                    />
                    </div>



                    <div className="form-field">
                    <label>Phone</label>
                    <input
                        type="text"
                        name="delivery_phone"
                        value={rowData.delivery_phone || ''}
                        onChange={handleInputChange}
                    />
                    </div>


                    
                    <div className="form-field">
                    <label>Contact Name</label>
                    <input
                        type="text"
                        name="delivery_contact"
                        value={rowData.delivery_contact || ''}
                        onChange={handleInputChange}
                    />
                    </div>

                    
                    
                    {/* Add more fields as needed */}
                </div>

                

                {/* Consignment Category */}
                <div className="category-column">
                    <h2>Consignment</h2>


                    <div className="form-field">
                    <label>Contents Type</label>
                    <select
                        name="consignment_contents_type"
                        value={rowData.consignment_contents_type || ''}
                        onChange={handleInputChange}
                    >
                        <option value="">Select Contents Type</option>
                        <option value="DOCUMENTS">DOCUMENTS</option>
                        <option value="NON DOCUMENTS">NON DOCUMENTS</option>
                        {/* Add more options as needed */}
                </select>
                    </div>

                    <div className="form-field">
                    <label>Contents</label>
                    <input
                        type="text"
                        name="consignment_contents"
                        value={rowData.consignment_contents || ''}
                        onChange={handleInputChange}
                    />
                    </div>


                    <div className="form-field">
                    <label>Consignment Value</label>
                    <input
                        type="text"
                        name="consignment_value"
                        value={rowData.consignment_value || ''}
                        onChange={handleInputChange}
                    />
                    </div>

                    <div className="form-field">
                    <label>Currency</label>
                    <input
                        type="text"
                        name="consignment_currency"
                        value={rowData.consignment_currency || ''}
                        onChange={handleInputChange}
                    />
                    </div>

                    <div className="form-field">
                    <label>Origin Country</label>
                    <input
                        type="text"
                        name="consignment_origin_country"
                        value={rowData.consignment_origin_country || ''}
                        onChange={handleInputChange}
                    />
                    </div>
                    {/* Add more fields as needed */}
                </div>
              </div>
            </div>
            

            {/* Dimensions & Weight Table */}
            <div className="data-table-container">
                <h2>Dimensions & Weight</h2>


                <table className="data-table">
                    <thead>
                        <tr>
                            <th>Qty</th>
                            <th>Length(cm)</th>
                            <th>Width(cm)</th>
                            <th>Height(cm)</th>
                            <th>Weight</th>
                            {/* Add more columns as needed */}
                        </tr>
                    </thead>
                    <tbody>
                        {/* Generate rows based on the largest dimensions array */}
                        {[...Array(maxRows)].map((_, index) => (
                            <tr key={index}>
                            {/* Additional column for row number */}
                            <td>{index + 1}</td> {/* Display row number starting from 1 */}

                            {/* Input for dimensions_length */}
                            <td>
                                <input
                                type="text"
                                name="dimensions_length"
                                value={dimensions_length[index] || ''} // Safely handle undefined array elements
                                onChange={(event) => handleInputTableChange(event, index, 'dimensions_length')}
                                />
                            </td>

                            {/* Input for dimensions_width */}
                            <td>
                                <input
                                type="text"
                                name="dimensions_width"
                                value={dimensions_width[index] || ''}
                                onChange={(event) => handleInputTableChange(event, index, 'dimensions_width')}
                                />
                            </td>

                            {/* Input for dimensions_height */}
                            <td>
                                <input
                                type="text"
                                name="dimensions_height"
                                value={dimensions_height[index] || ''}
                                onChange={(event) => handleInputTableChange(event, index, 'dimensions_height')}
                                />
                            </td>

                            {/* Input for dimensions_weight */}
                            <td>
                                <input
                                type="text"
                                name="dimensions_weight"
                                value={dimensions_weight[index] || ''}
                                onChange={(event) => handleInputTableChange(event, index, 'dimensions_weight')}
                                />
                            </td>
                            </tr>
                        ))}
                        </tbody>
                </table>
            </div>
            <br></br>
            <div>
                <button onClick={handleGetQuote}>Get Quote</button>
                {loading && <p>Loading...</p>}
            </div>
            

        </>
    );


}



export default ValidateInvoicePage;
