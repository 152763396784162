import { createContext, useContext, useState } from 'react';

// Create a context
const InvoiceRowDataContext = createContext();
//console.log('Context created:', InvoiceRowDataContext);

// Create a custom hook to access the context
export const useInvoiceRowData = () => {

    const context = useContext(InvoiceRowDataContext);
    console.log('Context being used :', context);
    if (!context) {
      throw new Error('useInvoiceRowData must be used within an InvoiceRowDataProvider');
    }
    return context;
  };
// Create a provider component
export const InvoiceRowDataProvider = ({ children }) => {
  const [rowData, setRowData] = useState({
    delivery_company: 'Delivery Company Name',
    delivery_street: 'Delivery Company Street',
    delivery_state: 'Delivery Company State',
    delivery_city: 'Delivery Company City',
    delivery_country: 'Delivery Company Country',
    delivery_postcode: 'Delivery Company Postcode',
    delivery_contact: 'Delivery Company Contact',
    delivery_phone: 'Delivery Company Phone',
    collection_company: 'Collection Company',
    collection_street: 'Collection Street',
    collection_state: 'Collection State',
    collection_city: 'Collection City',
    collection_country: 'Collection Country',
    collection_postcode: 'Collection Postcode',
    collection_contact: 'Collection Contact',
    collection_phone: 'Collection Phone',
    consignment_contents_type: 'Consignment Contents Type',
    consignment_contents: 'Consignment Contents',
    consignment_value: 0,
    consignment_currency: 'Consignment Currency',
    consignment_origin_country: 'Consignment Origin Country',
    // Now arrays for dimensions
    dimensions_lengths: [],
    dimensions_widths: [],
    dimensions_heights: [],
    dimensions_weights: []
  });

  return (
    <InvoiceRowDataContext.Provider value={{ rowData, setRowData }}>
      {children}
    </InvoiceRowDataContext.Provider>
  );
};


