// import logo from './images/courier_logo.jpg';
import React from 'react';
import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import NavBar from './NavBar';
import HomePage from './pages/HomePage.tsx';
import QuotesPage from './pages/QuotesPage';
import AboutPage from './pages/AboutPage';
import NotFoundPage from './pages/NotFoundPage';
import ValidateInvoicePage from './pages/ValidateInvoicePage';
import { InvoiceRowDataProvider } from './pages/InvoiceRowDataContext';
import { InvoiceFileDataProvider } from './pages/InvoiceFileDataContext';


function App() {

  return (
    <InvoiceFileDataProvider>
      <InvoiceRowDataProvider>
        <BrowserRouter>
        <div className="App">
        <NavBar />
        <div id="page-body">
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/about" element={<AboutPage />} />
            <Route path="/validate" element={<ValidateInvoicePage />} />
            <Route path="/quotes" element={<QuotesPage />} />
            <Route path='*' element={<NotFoundPage />} />
          </Routes>
          {/* add an image here */}

          {/* <img src={logo} alt="logo" /> */}
        </div>
        </div>
        </BrowserRouter>
      </InvoiceRowDataProvider>
    </InvoiceFileDataProvider>
  );
}

export default App;



