import axios from 'axios';
import config from './config-fe.mjs';
var API_URL;
if (config.env === 'DEV') {
  API_URL = config.API_URL_DEV;
}
else if (config.env === 'PROD') {
  API_URL = config.API_URL_PROD;

}


export const fetchInvoiceDataFromAPI = async (formData) => {

  try {
    const response = await axios.post(`${API_URL}/fetchInvoiceData`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    const data = response.data;

    return data;
  } catch (error) {
    throw error;
  }
};



export const getQuote = async (requestBody) => {
  try {
    const response = await axios.post(`${API_URL}/getQuote`, requestBody, {
      headers: {
        'Content-Type': 'application/json',
      },
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};