import React from 'react';
import { useLocation } from 'react-router-dom';

const QuotesPage = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const trackingNum = queryParams.get('trackingNum');
    return (
        <div>
          <h1>Quotes Page</h1>
          {trackingNum ? (
            <p>{trackingNum.includes('error') ? `Error: ${trackingNum}` : `Tracking Number: ${trackingNum}`}</p>
          ) : (
            <p>No Tracking Number Provided</p>
          )}
        </div>
      );
}

export default QuotesPage;
