import { Link } from 'react-router-dom';

const NavBar = () => (
    <nav>
        <ul>
            <li>
                <Link to="/">Home</Link>
            </li>
            <li>
                <Link to="/validate">Validate Invoice</Link>
            </li>
            <li>
                <Link to="/quotes">Quotes</Link>
            </li>
            <li>
                <Link to="/about">About</Link>
            </li>            
        </ul>
    </nav>
);

export default NavBar;