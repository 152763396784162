import React, { useState } from "react";
import {useNavigate} from "react-router-dom";
import { fetchInvoiceDataFromAPI } from "../apiService";
import {  useInvoiceRowData } from './InvoiceRowDataContext';
import { useInvoiceFileData } from './InvoiceFileDataContext';

const HomePage = () => {

  const [loading, setLoading] = useState(false);

  const { setRowData } = useInvoiceRowData();
  

  const { file, setFile } = useInvoiceFileData();

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0]; // Get the selected file
    if (selectedFile && selectedFile.type === 'application/pdf') {
       // Update the file state in the context
       setFile(selectedFile);
    } else {
      setFile(null);
      alert('Please select a PDF file');

      const emptyRowData = {
        delivery_company: 'Delivery Company Name',
            delivery_address: 'Delivery Company Address',
            delivery_city: 'Delivery Company City',
            delivery_country: 'Delivery Company Country',
            delivery_postcode: 'Delivery Company Postcode',
            delivery_contact: 'Delivery Company Contact',
            delivery_phone: 'Delivery Company Phone',
            collection_company: 'Collection Company',
            collection_address: 'Collection Address',
            collection_city: 'Collection City',
            collection_country: 'Collection Country',
            collection_postcode: 'Collection Postcode',
            collection_contact: 'Collection Contact',
            collection_phone: 'Collection Phone',
            consignment_contents_type: 'Consignment Contents Type',
            consignment_contents: 'Consignment Contents',
            consignment_value: 0,
            consignment_currency: 'Consignment Currency',
            consignment_origin_country: 'Consignment Origin Country',
            dimensions_weight: 0,
            dimensions_length: 0,
            dimensions_width: 0,
            dimensions_height: 0
      };
      setRowData(emptyRowData);

    }
  };

  const navigate = useNavigate();

  const navigateValidateInvoice = async () => {
    if (file) {
      const formData = new FormData();
      formData.append('pdfInvoiceFile', file);
    try {
      setLoading(true);
      const data = await fetchInvoiceDataFromAPI(formData);
      setRowData(data); // Update the data in the context
    } catch (error) {
      alert(error.message)
    }
    finally {
      setLoading(false);
      navigate('/validate');
    }
  }
 /*  else {
    alert('You need a PDF file');
  }; */
  }


   return (
    <>
      <h1>Courier Booking Landing Page</h1>  
        <p>
            Welcome to the Courier Booking we supply 
            In here you can get quotes for your courier needs by uploading your Invoice PDF 
            and our logic will automatically compare the prices of the courier companies and
            generate an offer you can choose from.
                
        </p>
        <p>
            You can then create a booking right here on our website 
            and we will send you a BL number and a tracking number for your parcel.
        </p>
        <br></br><br></br>
      <div>
        <label htmlFor="file" className="sr-only">
          Choose your invoice PDF
          
        </label>
        <br></br>
        <input id="file" type="file" accept=".pdf" onChange={handleFileChange} />
    

        {file ? (
        <div>
          <h2>Invoice File Details:</h2>
          <span>File Name: {file.name}</span>
          <br></br>
          {/* <span>File Type: {file.type}</span> */}
          <br></br>
  
        </div>
      ) : null}
      <br></br>
      </div>
      

      <div>
      {file && (
        <>
          <button onClick={navigateValidateInvoice} disabled={loading}>
            Validate Invoice
          </button>
          {loading && <p>Loading...</p>}
        </>
      )}
    </div>
    </>
  );
};

export default HomePage;