import { createContext, useContext, useState } from 'react';

const InvoiceFileDataContext = createContext();

export const useInvoiceFileData = () => {
  const context = useContext(InvoiceFileDataContext);
  if (!context) {
    throw new Error('useInvoiceFileData must be used within an InvoiceFileDataProvider');
  }
  return context;
};

export const InvoiceFileDataProvider = ({ children }) => {
  const [file, setFile] = useState(null);

  return (
    <InvoiceFileDataContext.Provider value={{ file, setFile }}>
      {children}
    </InvoiceFileDataContext.Provider>
  );
};